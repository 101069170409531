import React from "react"
import { graphql } from "gatsby"
import BlogComponent from "../../../components/blog/BlogComponent"

class Blog extends React.Component {
  render() {
    const { data, navigate, location } = this.props
    const posts = data.allMdx.edges

    return (
      <BlogComponent 
        posts={posts}
        navigate={navigate}
        location={location}
        prefix={'/sv/blog/'}
      />
    )
  }
}

export default Blog

export const pageQuery = graphql
`query {
    allMdx(
      filter: {frontmatter: {templateKey: {eq: "sv-blog"}}}, 
      sort: { fields: [frontmatter___date], order: DESC }) {
        edges {
          node {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              blogImage {
                childImageSharp {
                    fluid(maxWidth: 2048, quality: 100) {
                    ...GatsbyImageSharpFluid
                    }
                }
      }
              textBlocks {
                boldText
                text
              }
            }
          }
        }
      }
    }`
  